import { useState, useEffect, useCallback } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ApiReferenceReact } from "@scalar/api-reference-react";
import "@scalar/api-reference-react/style.css";
import swaggerJson from "./swagger.json";
import swaggerJsonProd from "./prod.swagger.json";
import swaggerJsonStage from "./stage.swagger.json";
import Navbar from "./components/navbar";
import GettingStarted from "components/gettingStarted";

function AuthHandler() {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const accessToken = params.get("access_token");

    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      if (window.opener) {
        window.opener.location.reload();
        window.close();
      } else {
        window.location.reload();
      }
    }
  }, [location]);

  return null;
}

function App() {
  const [accessToken] = useState(localStorage.getItem("accessToken"));
  const apiEnvironment = process.env.REACT_APP_API_ENVIRONMENT;
  const apiUrl = process.env.REACT_APP_API_URL;
  const remoteSpecUrl = process.env.REACT_APP_API_SPECS_URL;
  const authClientId = process.env.REACT_APP_API_AUTH0_CLIENT_ID;

  const getFallbackSwagger = useCallback(() => {
    switch (apiEnvironment) {
      case "Production":
        return swaggerJsonProd;
      case "Staging":
        return swaggerJsonStage;
      default:
        return swaggerJson;
    }
  }, [apiEnvironment]);

  const [specContent, setSpecContent] = useState(getFallbackSwagger);

  useEffect(() => {
    const fetchRemoteSpec = async () => {
      try {
        const response = await fetch(remoteSpecUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setSpecContent(data);
      } catch (error) {
        console.warn(
          `Failed to fetch remote spec for ${apiEnvironment}, using local file`,
          error
        );
        setSpecContent(getFallbackSwagger());
      }
    };

    if (remoteSpecUrl) {
      fetchRemoteSpec();
    }
  }, [remoteSpecUrl, apiEnvironment, getFallbackSwagger]);

  return (
    <div className="bg-primary min-h-screen">
      <Navbar />
      <div className="container mx-auto max-w-8xl">
        <div>
          <Routes>
            <Route path="/" element={<GettingStarted />} />
            <Route
              path="/documentation"
              element={
                <>
                  <AuthHandler />
                  <ApiReferenceReact
                    configuration={{
                      spec: {
                        content: specContent,
                      },
                      hideModels: true,
                      servers: [
                        {
                          url: apiUrl,
                          description: apiEnvironment,
                        },
                      ],
                      authentication: {
                        preferredSecurityScheme: "Bearer",
                        oAuth2: {
                          clientId: authClientId,
                          scopes: ["openid", "profile", "email"],
                          accessToken: accessToken,
                          password: "",
                          state: "",
                          username: "",
                        },
                      },
                      darkMode: true,
                      hideDarkModeToggle: true,
                    }}
                  />
                </>
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
