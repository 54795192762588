export default function AccessControlContent() {
  return (
    <div>
      <p className="mb-4">
        The JustinLegal API implements an access control system to ensure data
        security and privacy:
      </p>
      <ul className="list-disc pl-6 space-y-2">
        <li>Authentication is required for most endpoints using API keys.</li>
        <li>
          Certain endpoints allow anonymous access, particularly for creating
          and updating specific entities.
        </li>
        <li>
          Access to sensitive data may be restricted based on the associated
          CaseFile's permissions.
        </li>
        <li>
          Access to entities is generally governed by the associated CaseFile's
          permissions.
        </li>
      </ul>
    </div>
  );
}
