import { NavLink } from "react-router-dom";
import logo from "../assets/images/justin-logo-full.svg";

function Navbar() {
  return (
    <nav className="bg-primary-light p-4 sticky top-0 z-10 md:static">
      <div className="container mx-auto max-w-8xl px-4">
        <div className="flex justify-between items-center">
          <NavLink to="/" className="flex items-center">
            <img src={logo} alt="Logo" className="h-8 w-auto ml-8 md:ml-0" />
          </NavLink>
          <div className="flex-grow flex justify-center">
            <div className="flex space-x-4">
              <CustomNavLink to="/">Getting Started</CustomNavLink>
              <CustomNavLink to="/documentation">API References</CustomNavLink>
            </div>
          </div>
          <div className="w-[100px]"></div>
        </div>
      </div>
    </nav>
  );
}

function CustomNavLink({ to, children }) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `transition-colors duration-200 text-white font-semibold text-sm px-3 py-2 rounded-md ${
          isActive ? "bg-primary-lighter" : "hover:bg-primary-lighter"
        }`
      }
    >
      {children}
    </NavLink>
  );
}

export default Navbar;
