export default function AuthenticationContent() {
  return (
    <div>
      <h3 className="text-xl font-semibold mb-3">API Key Authentication</h3>
      <p className="mb-4">
        Most endpoints in the JustinLegal API require authentication using an
        API key. To authenticate your requests:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Include your API key in the <code>X-Api-Key</code> header of your
          requests.
        </li>
        <li>
          Some endpoints allow anonymous access, particularly for creating and
          updating certain entities.
        </li>
        <li>
          Access to sensitive data may still be restricted based on the
          associated CaseFile's permissions.
        </li>
      </ul>

      <h3 className="text-xl font-semibold mb-3">Obtaining an API Key</h3>
      <p className="mb-4">To obtain an API key, follow these steps:</p>
      <ol className="list-decimal pl-6 mb-4">
        <li>Ensure you have a JustinLegal account with admin rights</li>
        <li>
          Log in to {" "}
          <a
            href="https://portal.justin-legal.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            JustinLegal Portal
          </a>
          .
        </li>
        <li>
          Navigate to:
          <ul className="list-disc pl-6">
            <li>Einstellungen &gt; API </li>
          </ul>
        </li>
        <li>Request an API key from this section.</li>
      </ol>

      <h3 className="text-xl font-semibold mb-3">Security Considerations</h3>
      <p className="mb-4">
        For security reasons, when requesting an API key, you must provide a
        regex expression defining the allowed origins for requests associated
        with that API key. This helps ensure that your API key can only be used
        from authorized sources.
      </p>
    </div>
  );
}
