import { CodeSnippet } from "components/codeSnippet";

export default function RelationshipContent() {
  return (
    <div>
      <p className="mb-4">
        Relationships in the JustinLegal API represent the connections between
        Contacts and CaseFiles, defining the role and nature of a contact's
        involvement in a legal case.
      </p>

      <h3 className="text-xl font-semibold mb-3">Relationship Post Body</h3>
      <CodeSnippet
        schema={`
{
"type": "object",
"properties": {
"caseRole": {
"type": "integer",
"format": "int32"
},
"lawFirmRelation": {
"enum": [
"Client",
"Opponent",
"Witness",
"MainClient",
"Other"
],
"type": "string"
},
"hasRequestedLegalAid": {
"type": "boolean",
"nullable": true
},
"customFieldValues": {
"type": "array",
"items": {
"type": "object",
"properties": {
"customFieldDefinitionId": {
"type": "string",
"format": "uuid"
},
"type": {
"enum": [
"ShortText",
"Option",
"Number",
"Date",
"Time",
"Address",
"File",
"Money",
"LongText",
"TimePeriod",
"Checkbox",
"Email",
"PhoneNumber",
"ContactReference",
"Group",
"Description",
"Title",
"Spacer"
],
"type": "string"
}
},
"additionalProperties": false
},
"nullable": true
},
"caseFileId": {
"type": "string",
"format": "uuid"
},
"contactId": {
"type": "string",
"format": "uuid"
},
"entitySchemaId": {
"type": "string",
"format": "uuid",
"nullable": true
},
"fileEntries": {
"type": "array",
"items": {
"type": "object",
"properties": {
"id": {
"type": "string",
"format": "uuid"
}
},
"additionalProperties": false
},
"nullable": true
}
},
"additionalProperties": false
}`}
        example={`{
"caseRole": 1,
"lawFirmRelation": "Client",
"hasRequestedLegalAid": false,
"customFieldValues": [
{
"customFieldDefinitionId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
"type": "ShortText"
}
],
"caseFileId": "3fa85f64-5717-4562-b3fc-2c963f66afa7",
"contactId": "3fa85f64-5717-4562-b3fc-2c963f66afa8",
"entitySchemaId": "3fa85f64-5717-4562-b3fc-2c963f66afa9"
}`}
      />
      <h3 className="text-2xl font-semibold my-3 mt-8">Parameters</h3>
      <table className="min-w-full bg-primary overflow-hidden">
        <thead>
          <tr>
            <th
              className="px-4 py-3 bg-primary-dark text-left font-bold text-lg border border-white"
              colSpan={3}
            >
              Relationship
            </th>
          </tr>
          <tr className="bg-primary-dark">
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Operation
            </th>
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Field
            </th>
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-3 border border-white" rowSpan={7}>
              POST /api/v1/Relationship (Create)
            </td>
            <td className="px-4 py-3 border border-white">caseFileId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the case file associated with this
              relationship.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">contactId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the contact associated with this
              relationship.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">entitySchemaId</td>
            <td className="px-4 py-3 border border-white">
              Optional. Unique identifier (UUID) of the entity schema for custom
              fields.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">lawFirmRelation</td>
            <td className="px-4 py-3 border border-white">
              Type of relation to the law firm. Enum: Client, Opponent, Witness,
              MainClient, Other.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              hasRequestedLegalAid
            </td>
            <td className="px-4 py-3 border border-white">
              Boolean indicating if legal aid has been requested for the Contact
              associated with this Relationship
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">customFieldValues</td>
            <td className="px-4 py-3 border border-white">
              Array of custom field values associated with the relationship.
              They have to comply with the EntitySchema.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">fileEntries</td>
            <td className="px-4 py-3 border border-white">
              Array of file entry references (UUIDs) associated with the
              relationship.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white" rowSpan={4}>
              PUT /api/v1/Relationship/Relationship ID (Update)
            </td>
            <td className="px-4 py-3 border border-white">relationshipId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the relationship to update.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">lawFirmRelation</td>
            <td className="px-4 py-3 border border-white">
              Updated type of relation to the law firm.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              hasRequestedLegalAid
            </td>
            <td className="px-4 py-3 border border-white">
              Updated boolean indicating if legal aid has been requested.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">customFieldValues</td>
            <td className="px-4 py-3 border border-white">
              Updated array of custom field values.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              GET /api/v1/Relationship/Relationship ID
            </td>
            <td className="px-4 py-3 border border-white">relationshipId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the relationship to retrieve.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              DELETE /api/v1/Relationship/Relationship ID
            </td>
            <td className="px-4 py-3 border border-white">relationshipId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the relationship to delete.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
