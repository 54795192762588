export default function FormSchemaContent() {
  return (
    <div>
      <section className="mb-8">
        <p className="mb-4">
          FormSchemas define the structure of CaseFiles, specifying what data
          should be collected for different types of legal cases. They are
          associated with specific Areas of Law and CaseTypes, allowing for
          customized data collection across various legal practices.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          CaseFile Creation Process
        </h2>
        <ol className="list-decimal pl-6 mb-4">
          <li>Get FormSchema list from API</li>
          <li>Choose an appropriate FormSchema.</li>
          <li>
            When Creating a new CaseFile the FormSchemaId needs to be included
            in that request.
          </li>
          <li>
            Users can then populate the CaseFile with data according to the
            schema.
          </li>
        </ol>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          Key Components of a FormSchema
        </h2>

        <h3 className="text-xl font-semibold mb-2">1. FormSections</h3>
        <p className="mb-4">
          FormSections organize the CaseFile into logical groups of information.
          Each section can contain multiple custom fields and may be associated
          with a specific EntitySchema.
        </p>

        <h3 className="text-xl font-semibold mb-2">2. EntitySchemas</h3>
        <p className="mb-4">
          EntitySchemas define the structure for specific types of data within a
          CaseFile, such as client information, opposing parties, or
          case-specific details. They determine what CustomFields are available
          for each entity type.
        </p>

        <h3 className="text-xl font-semibold mb-2">3. CustomFields</h3>
        <p className="mb-4">
          CustomFields are the individual data points collected within a
          CaseFile. They can be of various types:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Short Text</li>
          <li>Long Text</li>
          <li>Number</li>
          <li>Date</li>
          <li>Options (single or multiple choice)</li>
          <li>File Upload</li>
        </ul>
        <p className="mb-4">
          Each CustomField can have its own validation rules and display
          options.
        </p>
      </section>
    </div>
  );
}
