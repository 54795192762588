import KeyConceptsContent from "./sections/keyConceptsContent";
import AuthenticationContent from "./sections/authenticationContent";
import AccessControlContent from "./sections/accesscontrolContent";
import WorkflowContent from "./sections/workflowContent";
import EntitiesOverviewContent from "./sections/entitiesOverviewContent";
import CaseFileContent from "./sections/caseFileContent";
import ContactContent from "./sections/contactContent";
import RelationshipContent from "./sections/relationshipContent";
import InsuranceContent from "./sections/insuranceContent";
import CustomEntityContent from "./sections/customEntityContent";
import CaseDocumentContent from "./sections/caseDocumentContent";
import NextStepsContent from "./sections/nextStepsContent";
import FormSchemaContent from "./sections/formSchemaContent";

export const sectionsConfig = [
  { id: "key-concepts", title: "Key Concepts", component: KeyConceptsContent },
  {
    id: "authentication",
    title: "Authentication",
    component: AuthenticationContent,
  },
  {
    id: "access-control",
    title: "Access Control",
    component: AccessControlContent,
  },
  {
    id: "form-schema",
    title: "FormSchemas",
    component: FormSchemaContent,
  },
  {
    id: "typical-workflow",
    title: "Typical Workflow",
    component: WorkflowContent,
  },
  {
    id: "entities",
    title: "Entities",
    component: EntitiesOverviewContent,
    subItems: [
      { id: "casefile", title: "CaseFiles", component: CaseFileContent },
      { id: "contact", title: "Contacts", component: ContactContent },
      {
        id: "relationship",
        title: "Relationships",
        component: RelationshipContent,
      },
      { id: "insurance", title: "Insurances", component: InsuranceContent },
      {
        id: "custom-entity",
        title: "Custom Entities",
        component: CustomEntityContent,
      },
      {
        id: "case-document",
        title: "Case Documents",
        component: CaseDocumentContent,
      },
    ],
  },
  { id: "next-steps", title: "Next Steps", component: NextStepsContent },
];
