export default function EntitiesOverviewContent() {
  return (
    <div>
      <p className="mb-4">
        The JustinLegal API is built around several key entities that work
        together to represent and manage legal cases:
      </p>
      <ul className="list-disc pl-6 space-y-4">
        <li>
          <strong>CaseFile:</strong> The central entity representing client
          matters, containing all related information and documents. CaseFiles
          progress through various statuses (e.g., Unsubmitted, Submitted,
          InProgress) and manage access control for related entities.
        </li>
        <li>
          <strong>Contacts:</strong> Individuals or organizations associated
          with case files. Contacts can be Natural Persons or Legal Persons,
          each with specific attributes and relationships to cases.
        </li>
        <li>
          <strong>Relationships:</strong> Connections between Contacts and
          CaseFiles, defining roles such as Client, Opponent, or Witness.
          Relationships are crucial for mapping the network of parties involved
          in a case.
        </li>
        <li>
          <strong>Insurances:</strong> Insurance information associated with
          CaseFiles and Contacts. This includes various types of insurance
          (e.g., vehicle, legal protection) and links to insurance companies.
        </li>
        <li>
          <strong>Custom Entities:</strong> Flexible data structures tailored to
          specific case types or law firm needs, allowing for extensible case
          management capabilities.
        </li>
        <li>
          <strong>FileEntries:</strong> Represents documents and files
          associated with CaseFiles and other entities. Includes metadata, blob
          storage information, and supports various document types and
          operations.
        </li>
        <li>
          <strong>EntitySchema:</strong> Defines the structure and validation
          rules for CustomizableBaseEntities, allowing for flexible and
          customizable data models.
        </li>
        <li>
          <strong>LawFirm:</strong> Represents the law firm using the system,
          crucial for access control and organization of cases and contacts.
        </li>
      </ul>
    </div>
  );
}
