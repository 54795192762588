import logo from "../../assets/images/test.drawio.svg";

export default function WorkflowContent() {
  return (
    <div>
      <div className="space-y-8">
        <img src={logo} alt="Logo" className="w-full py-12 px-6" />

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-white">
            0. Retrieving a FormSchema from the API
          </h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              For the Creation of a CaseFile you first need to get an eligible
              FormSchema from the API. You can first Get a list of all
              FormSchemas that are active for your LawFirm
            </li>
            <li>
              All data that is subsequently posted for a CaseFile will get
              validated against this FormSchema
            </li>
          </ul>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-white">
            1. Creating a CaseFile
          </h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              To start a new case, create a CaseFile by sending a POST request
              with the LawFirm ID and optionally a FormSchema ID.
            </li>
            <li>
              The API will return a new CaseFile ID, which you'll use for all
              subsequent operations.
            </li>
          </ul>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-white">
            2. Adding Contacts and Relationships
          </h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              Add people or organizations involved in the case by creating
              Contacts.
            </li>
            <li>
              Then, create Relationships to link these Contacts to your
              CaseFile.
            </li>
            <li>
              Specify the role of each Contact (e.g., client, opponent) in the
              Relationship.
            </li>
            <li>
              You can add multiple Relationships to represent different parties
              in the case.
            </li>
          </ul>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-white">
            3. Adding Insurances
          </h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              If the case involves insurance, you can add Insurance entities to
              the CaseFile.
            </li>
            <li>
              Link each Insurance to relevant Contacts (policyholder and
              co-insureds).
            </li>
            <li>
              You can either reference existing insurance companies or create
              new ones.
            </li>
          </ul>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-white">
            4. Uploading Documents
          </h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>Add documents to your case by creating FileEntry objects.</li>
            <li>
              You can associate documents directly with the CaseFile, or with
              specific Relationships or Insurances.
            </li>
            <li>
              Different types of documents are supported (e.g., case documents,
              insurance documents, relationship documents).
            </li>
          </ul>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-white">
            5. Updating Information
          </h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              As the case progresses, you can update Relationships, Insurances,
              and other details.
            </li>
            <li>Use the appropriate UPDATE endpoints for each entity type.</li>
            <li>
              Remember to include the CaseFile ID in your requests to ensure
              you're updating the correct case.
            </li>
          </ul>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-white">
            6. Submitting the CaseFile
          </h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              Once you've added all necessary information, submit the CaseFile.
            </li>
            <li>This marks the case as ready for review by the law firm.</li>
          </ul>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-white">
            7. Post-Submission Actions
          </h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              After submission, the law firm will perform a conflict check.
            </li>
            <li>
              The API allows for accepting or rejecting the conflict check.
            </li>
            <li>If accepted, the case moves to an "In Progress" state.</li>
          </ul>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-white">
            8. Generating Documents
          </h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              You can request the generation of specific documents (e.g., power
              of attorney, case summary).
            </li>
            <li>
              These will be created as new FileEntry objects associated with
              your CaseFile.
            </li>
          </ul>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-white">
            9. Exporting the CaseFile
          </h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              Once the case is complete or when needed, you can request an
              export of the entire CaseFile.
            </li>
            <li>
              This prepares all case data for integration with the law firm's
              systems.
            </li>
          </ul>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-white">
            10. Monitoring Case Status
          </h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              Throughout the process, you can check the status of the CaseFile.
            </li>
            <li>
              Statuses include Unsubmitted, Submitted, In Progress, Completed,
              and others.
            </li>
          </ul>
        </section>

        <section className="mt-8 p-4 bg-primary-lighter rounded-lg">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Key Points to Remember
          </h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>Always include proper authentication in your API requests.</li>
            <li>
              Many operations require specific permissions, usually tied to the
              law firm associated with the case.
            </li>
            <li>
              The API maintains data integrity, so you'll need to ensure
              referenced entities (like Contacts) exist before linking them to
              your CaseFile.
            </li>
            <li>
              Custom fields may be available for Relationships and Insurances,
              allowing for flexible data storage as defined by the law firm's
              schemas.
            </li>
            <li>
              Document management is handled through FileEntry objects, which
              can be associated with various aspects of the case.
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}
