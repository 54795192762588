import { CodeSnippet } from "components/codeSnippet";

export default function CaseFileContent() {
  return (
    <div>
      {" "}
      <p className="mb-4">
        CaseFiles are the central entity in the JustinLegal API, representing
        individual legal matters and serving as containers for all related
        information. They provide a structured way to manage the entire
        lifecycle of a legal case.
      </p>
      <h3 className="text-xl font-semibold mb-3">Post Body</h3>
      <CodeSnippet
        schema={`{
"type": "object",
"properties": {
"lawFirmId": {
"type": "string",
"format": "uuid"
},
"formSchemaId": {
"type": "string",
"format": "uuid",
"nullable": true
},
"isDemo": {
"type": "boolean"
}
},
"additionalProperties": false
}`}
        example={`{
"lawFirmId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
"formSchemaId": "3fa85f64-5717-4562-b3fc-2c963f66afa7",
"isDemo": false
}`}
      />
      <h3 className="text-xl font-semibold my-3">CaseFile Status Workflow</h3>
      <p className="mb-2">
        CaseFiles progress through various statuses, represented by the
        CaseStatus enum:
      </p>
      <ul className="list-disc pl-6 space-y-1">
        <li>
          <strong>Unsubmitted:</strong> Initial state when a CaseFile is created
          but not yet submitted.
        </li>
        <li>
          <strong>Submitted:</strong> The CaseFile has been submitted by the
          client.
        </li>
        <li>
          <strong>ConflictCheckRejected:</strong> The conflict check has been
          rejected by a LawFirm Member.
        </li>
        <li>
          <strong>InProgress:</strong> The conflict check has been accepted, and
          work has begun on the case.
        </li>
        <li>
          <strong>Pending:</strong> The case is awaiting further action or
          information.
        </li>
        <li>
          <strong>Completed:</strong> All work on the case has been finished.
        </li>
        <li>
          <strong>Rejected:</strong> The case has been rejected by the LawFirm.
        </li>
      </ul>
      <h3 className="text-xl font-semibold my-3">Document Types</h3>
      <p className="mb-2">
        CaseFiles can be associated with various types of documents, classified
        by the CaseDocumentType enum:
      </p>
      <ul className="list-disc pl-6 space-y-1">
        <li>
          <strong>CaseFileDocument:</strong> Documents directly attached to the
          CaseFile.
        </li>
        <li>
          <strong>InsuranceDocument:</strong> Documents related to insurance
          information.
        </li>
        <li>
          <strong>GeneratedDocument:</strong> Documents generated from case
          information after the case has been accepted.
        </li>
        <li>
          <strong>CustomEntityDocument:</strong> Documents attached to custom
          entities within the CaseFile.
        </li>
        <li>
          <strong>RelationshipDocument:</strong> Documents associated with
          specific relationships in the case.
        </li>
      </ul>
      <h3 className="text-xl font-semibold my-3">Relationships</h3>
      <p className="mb-2">
        CaseFiles manage relationships with various parties involved in the
        case, defined by the LawFirmRelation enum:
      </p>
      <ul className="list-disc pl-6 space-y-1">
        <li>
          <strong>MainClient:</strong> The primary client who submitted the
          CaseFile.
        </li>
        <li>
          <strong>Client:</strong> Other clients involved in the case.
        </li>
        <li>
          <strong>Opponent:</strong> Opposing parties in the legal matter.
        </li>
        <li>
          <strong>Witness:</strong> Individuals providing testimony or
          information.
        </li>
        <li>
          <strong>Other:</strong> Any other type of relationship relevant to the
          case.
        </li>
      </ul>
      <h3 className="text-2xl font-semibold my-3 mt-8">Parameters</h3>
      <table className="min-w-full bg-primary overflow-hidden">
        <thead>
          <tr>
            <th
              className="px-4 py-3 bg-primary-dark text-left font-bold text-lg border border-white"
              colSpan={3}
            >
              CaseFile
            </th>
          </tr>
          <tr className="bg-primary-dark">
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Operation
            </th>
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Field
            </th>
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-3 border border-white" rowSpan={3}>
              POST /api/v1/CaseFile (Create)
            </td>
            <td className="px-4 py-3 border border-white">lawFirmId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the law firm associated with this case
              file.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">formSchemaId</td>
            <td className="px-4 py-3 border border-white">
              Optional. Unique identifier (UUID) of the form schema to be used
              for this case file. Defines the structure and custom fields for
              the case.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">isDemo</td>
            <td className="px-4 py-3 border border-white">
              Boolean flag indicating whether this is a demo case file.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white" rowSpan={2}>
              PUT /api/v1/CaseFile/Status/ID (Update Status)
            </td>
            <td className="px-4 py-3 border border-white">id</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the case file to update.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">status</td>
            <td className="px-4 py-3 border border-white">
              New status for the case file. Enum: Submitted,
              ConflictCheckRejected, InProgress, Completed, Rejected, Pending,
              Unsubmitted.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white" rowSpan={2}>
              PUT /api/v1/CaseFile/Owner/ID (Update Owner)
            </td>
            <td className="px-4 py-3 border border-white">id</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the case file to update.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">newOwnerId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the new owner (User) for this case
              file.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white" rowSpan={2}>
              PUT /api/v1/CaseFile/Goal/ID (Update Goal)
            </td>
            <td className="px-4 py-3 border border-white">id</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the case file to update.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">goal</td>
            <td className="px-4 py-3 border border-white">
              New goal or objective for the case file.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white" rowSpan={3}>
              POST /api/v1/CaseFile/Submit/ID (Submit)
            </td>
            <td className="px-4 py-3 border border-white">id</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the case file to submit.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">message</td>
            <td className="px-4 py-3 border border-white">
              Optional message to include with the submission.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">sendSubmitEmails</td>
            <td className="px-4 py-3 border border-white">
              Boolean flag indicating whether to send submission notification
              emails.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              GET /api/v1/CaseFile/ID
            </td>
            <td className="px-4 py-3 border border-white">id</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the case file to retrieve.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
