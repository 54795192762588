import { CodeSnippet } from "components/codeSnippet";

export default function CustomEntityContent() {
  return (
    <div>
      <p className="mb-4">
        CustomEntities are flexible data structures within a CaseFile that
        conform to a specific EntitySchema. They allow for the collection of
        complex, repeatable data sets. For example:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li>Multiple involved parties in a case</li>
        <li>Lists of assets in a divorce case</li>
        <li>Itemized damages in a personal injury case</li>
      </ul>
      <p className="mb-4">
        CustomEntities can be added, updated, or removed from a CaseFile as
        needed, providing dynamic data management within the constraints of the
        FormSchema.
      </p>

      <h3 className="text-xl font-semibold mb-3">Post Body</h3>
      <CodeSnippet
        schema={`
{
"type": "object",
"properties": {
"customFieldValues": {
"type": "array",
"items": {
"type": "object",
"properties": {
"customFieldDefinitionId": {
"type": "string",
"format": "uuid"
},
"type": {
"enum": [
"ShortText",
"Option",
"Number",
"Date",
"Time",
"Address",
"File",
"Money",
"LongText",
"TimePeriod",
"Checkbox",
"Email",
"PhoneNumber",
"ContactReference",
"Group",
"Description",
"Title",
"Spacer"
],
"type": "string"
}
},
"additionalProperties": false
},
"nullable": true
},
"caseFileId": {
"type": "string",
"format": "uuid"
},
"entitySchemaId": {
"type": "string",
"format": "uuid"
},
"fileEntries": {
"type": "array",
"items": {
"type": "object",
"properties": {
"id": {
"type": "string",
"format": "uuid"
}
},
"additionalProperties": false
},
"nullable": true
}
},
"additionalProperties": false
}`}
        example={`{
"customFieldValues": [
{
"customFieldDefinitionId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
"type": "ShortText"
}
],
"caseFileId": "3fa85f64-5717-4562-b3fc-2c963f66afa7",
"entitySchemaId": "3fa85f64-5717-4562-b3fc-2c963f66afa8"
}`}
      />

      <h3 className="text-xl font-semibold my-3">
        Validation and Customization
      </h3>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <strong>Schema Validation:</strong> CustomEntities are validated
          against their EntitySchema, ensuring data integrity and consistency.
        </li>
        <li>
          <strong>Custom Field Types:</strong> Support for various custom field
          types, including grouped fields and conditional validation.
        </li>
        <li>
          <strong>Contact References:</strong> CustomEntities can reference
          Contacts through special custom field values.
        </li>
        <li>
          <strong>File Attachments:</strong> Support for attaching and managing
          related documents.
        </li>
      </ul>

      <h3 className="text-2xl font-semibold my-3 mt-8">Parameters</h3>
      <table className="min-w-full bg-primary overflow-hidden">
        <thead>
          <tr>
            <th
              className="px-4 py-3 bg-primary-dark text-left font-bold text-lg border border-white"
              colSpan={3}
            >
              CustomEntity
            </th>
          </tr>
          <tr className="bg-primary-dark">
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Operation
            </th>
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Field
            </th>
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-3 border border-white" rowSpan={4}>
              POST /api/v1/CustomEntity (Create)
            </td>
            <td className="px-4 py-3 border border-white">caseFileId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the case file associated with this
              custom entity.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">entitySchemaId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the entity schema defining the
              structure of this custom entity.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">customFieldValues</td>
            <td className="px-4 py-3 border border-white">
              Array of custom field values associated with the custom entity, as
              defined by the entity schema.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">fileEntries</td>
            <td className="px-4 py-3 border border-white">
              Array of file entry references (UUIDs) associated with the custom
              entity.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white" rowSpan={2}>
              PUT /api/v1/CustomEntity/CustomEntity ID (Update)
            </td>
            <td className="px-4 py-3 border border-white">customEntityId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the custom entity to update.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">customFieldValues</td>
            <td className="px-4 py-3 border border-white">
              Updated array of custom field values for the custom entity.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              GET /api/v1/CustomEntity/CustomEntity ID
            </td>
            <td className="px-4 py-3 border border-white">customEntityId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the custom entity to retrieve.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              DELETE /api/v1/CustomEntity/CustomEntity ID
            </td>
            <td className="px-4 py-3 border border-white">customEntityId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the custom entity to delete.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
