import { useState } from "react";

export function CodeSnippet({ schema, example }) {
  const [showSchema, setShowSchema] = useState(false);

  const parseJSON = (json) => {
    if (typeof json === "string") {
      try {
        return JSON.parse(json);
      } catch (e) {
        console.error("Invalid JSON string:", e);
        return json;
      }
    }
    return json;
  };

  const schemaObj = parseJSON(schema);
  const exampleObj = parseJSON(example);

  return (
    <div className="mb-8">
      <div className="flex rounded-t-md overflow-hidden">
        <button
          className={`px-4 py-2 text-sm font-medium ${
            !showSchema
              ? "bg-primary-lighter text-white"
              : "bg-primary-light text-gray-300 hover:bg-primary-lighter hover:text-white"
          } transition-colors duration-200`}
          onClick={() => setShowSchema(false)}
        >
          Example
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            showSchema
              ? "bg-primary-lighter text-white"
              : "bg-primary-light text-gray-300 hover:bg-primary-lighter hover:text-white"
          } transition-colors duration-200`}
          onClick={() => setShowSchema(true)}
        >
          Schema
        </button>
      </div>
      <pre className="bg-gray-800 p-4 rounded-b-md overflow-x-auto whitespace-pre-wrap break-words">
        <code className="text-gray-200 text-sm">
          {JSON.stringify(showSchema ? schemaObj : exampleObj, null, 2)}
        </code>
      </pre>
    </div>
  );
}
