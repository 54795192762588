// src/components/Sidebar.js

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

function Sidebar({
  menuConfig,
  activeSection,
  setActiveSection,
  isOpen,
  onClose,
}) {
  const navigate = useNavigate();
  const [expandedItems, setExpandedItems] = useState({});

  const scrollToSection = (sectionId) => {
    setActiveSection(sectionId);
    navigate(`#${sectionId}`, { replace: true });
    scroller.scrollTo(sectionId, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -20,
    });
    if (window.innerWidth < 768) {
      onClose();
    }
  };

  const toggleExpand = (id) => {
    setExpandedItems((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const renderMenuItem = (item, depth = 0) => (
    <li key={item.id}>
      <div className="flex items-center">
        <button
          onClick={() => scrollToSection(item.id)}
          className={`flex-grow text-left py-2 px-4 rounded-md transition-colors duration-200 ${
            activeSection === item.id
              ? "bg-primary-lighter text-white font-semibold"
              : "hover:bg-primary-light text-gray-300"
          }`}
          style={{ paddingLeft: `${depth * 1 + 1}rem` }}
        >
          {item.title}
        </button>
        {item.subItems && (
          <button
            onClick={() => toggleExpand(item.id)}
            className="p-2 text-gray-300 hover:text-white"
          >
            {expandedItems[item.id] ? (
              <ChevronDownIcon className="w-5 h-5" />
            ) : (
              <ChevronRightIcon className="w-5 h-5" />
            )}
          </button>
        )}
      </div>
      {item.subItems && expandedItems[item.id] && (
        <ul className="mt-2 space-y-2">
          {item.subItems.map((subItem) => renderMenuItem(subItem, depth + 1))}
        </ul>
      )}
    </li>
  );

  return (
    <nav
      className={`fixed inset-y-0 left-0 transform ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } w-64 bg-primary p-6 overflow-y-auto transition-transform duration-200 ease-in-out z-30 md:translate-x-0 md:sticky md:top-0 md:h-screen`}
    >
      <button
        className="md:hidden absolute top-4 right-4 text-white"
        onClick={onClose}
      >
        ✕
      </button>
      <ul className="space-y-4 mt-8 md:mt-0">
        {menuConfig.map((item) => renderMenuItem(item))}
      </ul>
    </nav>
  );
}

export default Sidebar;
