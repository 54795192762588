import { CodeSnippet } from "components/codeSnippet";

export default function InsuranceContent() {
  return (
    <div>
      {" "}
      <p className="mb-4">
        Insurances in the JustinLegal API represent insurance policies
        associated with CaseFiles and Contacts. They provide a structured way to
        manage insurance information relevant to legal cases.
      </p>
      <h3 className="text-xl font-semibold mb-3">Post Body</h3>
      <CodeSnippet
        schema={`
{
"type": "object",
"properties": {
"insuranceNumber": {
"type": "string",
"nullable": true
},
"claimNumber": {
"type": "string",
"nullable": true
},
"insuranceType": {
"enum": [
"LegalProtection",
"Liability",
"Car",
"Accident",
"Other"
],
"type": "string"
},
"customInsuranceType": {
"type": "string",
"nullable": true
},
"policyHolderId": {
"type": "string",
"format": "uuid"
},
"coInsuredIds": {
"type": "array",
"items": {
"type": "string",
"format": "uuid"
},
"nullable": true
},
"legalProtectionInsuranceTypes": {
"uniqueItems": true,
"type": "array",
"items": {
"enum": [
"PrivateLegalProtection",
"ProfessionalLegalProtection",
"TrafficLegalProtection",
"RentLegalProtection",
"LandlordLegalProtection"
],
"type": "string"
},
"nullable": true
},
"vehicleInsuranceType": {
"enum": [
"LiabilityOnly",
"PartlyComprehensive",
"FullyComprehensive"
],
"type": "string"
},
"vehicleLicensePlate": {
"type": "string",
"nullable": true
},
"deductible": {
"type": "object",
"properties": {
"display": {
"type": "string",
"nullable": true,
"readOnly": true
},
"currency": {
"enum": [
"EUR",
"USD",
"GBP"
],
"type": "string"
},
"amountInCents": {
"type": "integer",
"format": "int64"
}
},
"additionalProperties": false
},
"partlyComprehensiveDeductible": {
"type": "object",
"properties": {
"display": {
"type": "string",
"nullable": true,
"readOnly": true
},
"currency": {
"enum": [
"EUR",
"USD",
"GBP"
],
"type": "string"
},
"amountInCents": {
"type": "integer",
"format": "int64"
}
},
"additionalProperties": false
},
"comprehensiveDeductible": {
"type": "object",
"properties": {
"display": {
"type": "string",
"nullable": true,
"readOnly": true
},
"currency": {
"enum": [
"EUR",
"USD",
"GBP"
],
"type": "string"
},
"amountInCents": {
"type": "integer",
"format": "int64"
}
},
"additionalProperties": false
},
"baFinId": {
"type": "integer",
"format": "int32",
"nullable": true
},
"insuranceCompanyName": {
"type": "string",
"nullable": true
},
"customFieldValues": {
"type": "array",
"items": {
"type": "object",
"properties": {
"customFieldDefinitionId": {
"type": "string",
"format": "uuid"
},
"type": {
"enum": [
"ShortText",
"Option",
"Number",
"Date",
"Time",
"Address",
"File",
"Money",
"LongText",
"TimePeriod",
"Checkbox",
"Email",
"PhoneNumber",
"ContactReference",
"Group",
"Description",
"Title",
"Spacer"
],
"type": "string"
}
},
"additionalProperties": false
},
"nullable": true
},
"fileEntries": {
"type": "array",
"items": {
"type": "object",
"properties": {
"id": {
"type": "string",
"format": "uuid"
}
},
"additionalProperties": false
},
"nullable": true
},
"caseFileId": {
"type": "string",
"format": "uuid"
},
"entitySchemaId": {
"type": "string",
"format": "uuid",
"nullable": true
}
},
"additionalProperties": false
}`}
        example={`{
"insuranceNumber": "INS-12345",
"claimNumber": "CLM-67890",
"insuranceType": "LegalProtection",
"policyHolderId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
"coInsuredIds": [
"3fa85f64-5717-4562-b3fc-2c963f66afa7"
],
"legalProtectionInsuranceTypes": [
"PrivateLegalProtection"
],
"deductible": {
"currency": "EUR",
"amountInCents": 10000
},
"baFinId": 12345,
"insuranceCompanyName": "Example Insurance Co.",
"caseFileId": "3fa85f64-5717-4562-b3fc-2c963f66afa8"
}`}
      />
      <h3 className="text-xl font-semibold my-3">Insurance Types</h3>
      <p className="mb-2">Insurances can be of various types, including:</p>
      <ul className="list-disc pl-6 space-y-1">
        <li>
          Vehicle insurance (with subtypes like comprehensive or partly
          comprehensive)
        </li>
        <li>Legal protection insurance</li>
        <li>Custom insurance types</li>
      </ul>
      <h3 className="text-xl font-semibold my-3">Special Considerations</h3>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <strong>InsuranceCompany Handling:</strong> The API supports both
          custom insurance companies and official BaFin references. It
          automatically manages the creation, updating, and referencing of
          InsuranceCompany entities.
        </li>
        <li>
          <strong>Contact Verification:</strong> When creating or updating an
          Insurance, the API verifies that all referenced Contacts (policy
          holder and co-insured parties) exist and are associated with the
          correct LawFirm.
        </li>
        <li>
          <strong>Custom Fields:</strong> The API supports validation and
          updating of custom fields defined in the associated FormSchema.
        </li>
        <li>
          <strong>BaFin Reference Import:</strong> The service provides
          functionality to import and update BaFin insurance company references,
          maintaining an up-to-date database of official insurance companies.
        </li>
      </ul>
      <h3 className="text-2xl font-semibold my-3 mt-8">Parameters</h3>
      <table className="min-w-full bg-primary overflow-hidden">
        <thead>
          <tr>
            <th
              className="px-4 py-3 bg-primary-dark text-left font-bold text-lg border border-white"
              colSpan={3}
            >
              Insurance
            </th>
          </tr>
          <tr className="bg-primary-dark">
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Operation
            </th>
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Field
            </th>
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-3 border border-white" rowSpan={17}>
              POST /api/v1/Insurance (Create)
            </td>
            <td className="px-4 py-3 border border-white">caseFileId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the case file associated with this
              insurance.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">insuranceNumber</td>
            <td className="px-4 py-3 border border-white">
              Insurance policy number.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">claimNumber</td>
            <td className="px-4 py-3 border border-white">
              Claim number associated with the insurance.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">insuranceType</td>
            <td className="px-4 py-3 border border-white">
              Type of insurance. Enum: LegalProtection, Liability, Car,
              Accident, Other.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              customInsuranceType
            </td>
            <td className="px-4 py-3 border border-white">
              Custom insurance type if insuranceType is Other.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">policyHolderId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the policy holder (Contact).
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">coInsuredIds</td>
            <td className="px-4 py-3 border border-white">
              Array of unique identifiers (UUIDs) of co-insured contacts.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              legalProtectionInsuranceTypes
            </td>
            <td className="px-4 py-3 border border-white">
              Array of legal protection insurance types. Enum:
              PrivateLegalProtection, ProfessionalLegalProtection,
              TrafficLegalProtection, RentLegalProtection,
              LandlordLegalProtection.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              vehicleInsuranceType
            </td>
            <td className="px-4 py-3 border border-white">
              Type of vehicle insurance. Enum: LiabilityOnly,
              PartlyComprehensive, FullyComprehensive.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              vehicleLicensePlate
            </td>
            <td className="px-4 py-3 border border-white">
              License plate of the insured vehicle.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">deductible</td>
            <td className="px-4 py-3 border border-white">
              Deductible amount (MoneyDto object with currency and
              amountInCents).
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              partlyComprehensiveDeductible
            </td>
            <td className="px-4 py-3 border border-white">
              Partly comprehensive deductible amount (MoneyDto object).
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              comprehensiveDeductible
            </td>
            <td className="px-4 py-3 border border-white">
              Comprehensive deductible amount (MoneyDto object).
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">baFinId</td>
            <td className="px-4 py-3 border border-white">
              BaFin (Federal Financial Supervisory Authority) ID of the
              insurance company.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              insuranceCompanyName
            </td>
            <td className="px-4 py-3 border border-white">
              Name of the insurance company if not using BaFin reference.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">customFieldValues</td>
            <td className="px-4 py-3 border border-white">
              Array of custom field values associated with the insurance.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">fileEntries</td>
            <td className="px-4 py-3 border border-white">
              Array of file entry references (UUIDs) associated with the
              insurance.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white" rowSpan={2}>
              PUT /api/v1/Insurance/Insurance ID (Update)
            </td>
            <td className="px-4 py-3 border border-white">insuranceId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the insurance to update.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white" colSpan={2}>
              All fields from the POST operation can be updated, except for
              caseFileId.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              GET /api/v1/Insurance/ID
            </td>
            <td className="px-4 py-3 border border-white">id</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the insurance to retrieve.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              DELETE /api/v1/Insurance/Insurance ID
            </td>
            <td className="px-4 py-3 border border-white">insuranceId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the insurance to delete.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
