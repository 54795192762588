export default function KeyConceptsContent() {
  return (
    <div>
      <p className="mb-4">
        The JustinLegal API is designed to facilitate digital client onboarding
        and case management for law firms. Key concepts include:
      </p>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <strong>Digital Client Onboarding:</strong> Integrate the process of
          receiving new case files into your existing solutions.
        </li>
        <li>
          <strong>Case Management:</strong> Manage legal cases, from initial
          creation and editing to exporting to a variety of third party
          applications.
        </li>
        <li>
          <strong>Document Handling:</strong> Upload, generate, and manage
          case-related documents securely.
        </li>
      </ul>
    </div>
  );
}
