// src/components/GettingStarted.js

import React, { useState, useEffect } from "react";
import { Element } from "react-scroll";
import { useLocation } from "react-router-dom";
import BackToTopButton from "./controls/backToTopButton";
import { Bars3Icon } from "@heroicons/react/24/solid";
import Sidebar from "./sideBar";
import { sectionsConfig } from "./sectionsConfig";

function GettingStarted() {
  const [activeSection, setActiveSection] = useState("introduction");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.hash.slice(1);
    if (sectionId) {
      setActiveSection(sectionId);
    }
  }, [location]);

  const copyLinkToClipboard = (sectionId) => {
    const url = `${window.location.origin}${window.location.pathname}#${sectionId}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const renderContent = (items) => {
    return items.map((item) => (
      <React.Fragment key={item.id}>
        <Element name={item.id} id={item.id}>
          <section className="mb-12">
            <div className="bg-primary-light rounded-lg p-4 mb-4 flex justify-between items-center">
              <h2 className="text-2xl font-semibold">{item.title}</h2>
              <button
                onClick={() => copyLinkToClipboard(item.id)}
                className="text-sm bg-primary-lighter px-3 py-1 rounded hover:bg-primary transition-colors duration-200"
              >
                #
              </button>
            </div>
            <item.component />
          </section>
        </Element>
        {item.subItems && renderContent(item.subItems)}
      </React.Fragment>
    ));
  };

  return (
    <div className="flex flex-col md:flex-row text-gray-200 pt-16">
      <button
        className={`${
          isSidebarOpen && "hidden"
        } md:hidden fixed top-4 left-4 z-40 text-white bg-primary-light p-2 rounded-lg`}
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <Bars3Icon className="w-6 h-6" />
      </button>
      <Sidebar
        menuConfig={sectionsConfig}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        isOpen={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
      />
      <div className="flex-1 max-w-4xl mx-auto p-6">
        <BackToTopButton />
        <h1 className="text-4xl font-bold mb-6">
          Getting Started with JustinLegal API
        </h1>
        <p className="mb-12">
          Welcome to the JustinLegal API documentation. This API allows you to
          integrate our digital client onboarding system with your own
          applications. The core of our API revolves around the `CaseFile`
          entity and its related components, enabling you to streamline your law
          firm management and enhance your client service capabilities.
        </p>
        {renderContent(sectionsConfig)}
      </div>
    </div>
  );
}

export default GettingStarted;
