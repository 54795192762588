import { CodeSnippet } from "components/codeSnippet";

export default function CaseDocumentContent() {
  return (
    <div>
      {" "}
      <p className="mb-4">
        File Entries in the JustinLegal API represent documents and files
        associated with various entities in the system, primarily CaseFiles.
        They provide a structured way to manage and track documents throughout
        the lifecycle of a legal case.
      </p>
      <h3 className="text-xl font-semibold mb-3">Post Body</h3>
      <CodeSnippet
        schema={`
{
"type": "object",
"properties": {
"File": {
"type": "string",
"format": "binary"
},
"CaseFileId": {
"type": "string",
"format": "uuid"
},
"CaseDocumentType": {
"enum": [
"CaseFileDocument",
"InsuranceDocument",
"GeneratedDocument",
"GeneratedTestDocument",
"CustomEntityDocument",
"RelationshipDocument"
],
"type": "string"
},
"RelatedEntityId": {
"type": "string",
"format": "uuid"
},
"CustomFieldDefinitionId": {
"type": "string",
"format": "uuid"
}
},
"additionalProperties": false
}
`}
        example={`{
"File": "(binary file data)",
"CaseFileId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
"CaseDocumentType": "CaseFileDocument",
"RelatedEntityId": "3fa85f64-5717-4562-b3fc-2c963f66afa7",
"CustomFieldDefinitionId": "3fa85f64-5717-4562-b3fc-2c963f66afa8"
}`}
      />
      <h3 className="text-xl font-semibold my-3">Special Features</h3>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <strong>Document Generation:</strong> Support for generated documents
          with status tracking.
        </li>
        <li>
          <strong>Expiry Management:</strong> Unreferenced FileEntries have an
          expiry date for automatic cleanup.
        </li>
        <li>
          <strong>File Size Display:</strong> Automatic conversion of file size
          to human-readable format.
        </li>
        <li>
          <strong>Relationship Management:</strong> FileEntries can be
          associated with various entity types (Insurances, CustomEntities,
          Relationships) through mapping entities.
        </li>
        <li>
          <strong>Thumbnail Caching:</strong> Efficient caching of thumbnail
          links to reduce storage operations.
        </li>
      </ul>
      <h3 className="text-xl font-semibold my-3">
        Integration with Case Management
      </h3>
      <p className="mb-2">
        FileEntries play a crucial role in document management within the legal
        case context:
      </p>
      <ul className="list-disc pl-6 space-y-1">
        <li>
          Provide a centralized way to manage all documents related to a case.
        </li>
        <li>
          Support various document types, including uploaded files, generated
          documents, and entity-specific attachments.
        </li>
        <li>
          Enable efficient retrieval and management of case-related documents.
        </li>
        <li>
          Facilitate secure document sharing and access control within the law
          firm.
        </li>
      </ul>
      <h3 className="text-2xl font-semibold my-3 mt-8">Parameters</h3>
      <table className="min-w-full bg-primary overflow-hidden">
        <thead>
          <tr>
            <th
              className="px-4 py-3 bg-primary-dark text-left font-bold text-lg border border-white"
              colSpan={3}
            >
              CaseDocument / FileEntry
            </th>
          </tr>
          <tr className="bg-primary-dark">
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Operation
            </th>
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Field
            </th>
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-3 border border-white" rowSpan={5}>
              POST /api/v1/CaseDocument (Upload)
            </td>
            <td className="px-4 py-3 border border-white">File</td>
            <td className="px-4 py-3 border border-white">
              The actual file to be uploaded (multipart/form-data).
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">CaseFileId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the case file associated with this
              document.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">CaseDocumentType</td>
            <td className="px-4 py-3 border border-white">
              Type of the case document. Enum: CaseFileDocument,
              InsuranceDocument, GeneratedDocument, GeneratedTestDocument,
              CustomEntityDocument, RelationshipDocument.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">RelatedEntityId</td>
            <td className="px-4 py-3 border border-white">
              Optional. Unique identifier (UUID) of a related entity (e.g.,
              Insurance, CustomEntity, Relationship).
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              CustomFieldDefinitionId
            </td>
            <td className="px-4 py-3 border border-white">
              Optional. Unique identifier (UUID) of a custom field definition if
              this file is associated with a custom field.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white" rowSpan={2}>
              POST /api/v1/CaseDocument/changeFileName/FileEntry ID
            </td>
            <td className="px-4 py-3 border border-white">fileEntryId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the file entry to update.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">fileName</td>
            <td className="px-4 py-3 border border-white">
              New file name for the document.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white" rowSpan={2}>
              POST /api/v1/CaseDocument/generate
            </td>
            <td className="px-4 py-3 border border-white">caseFileId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the case file for which to generate a
              document.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              documentTemplateId
            </td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the document template to use for
              generation.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              GET /api/v1/CaseDocument/CaseFile ID/overview
            </td>
            <td className="px-4 py-3 border border-white">caseFileId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the case file to retrieve documents
              for.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              GET /api/v1/CaseDocument/download/FileEntry ID
            </td>
            <td className="px-4 py-3 border border-white">fileEntryId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the file entry to download.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              DELETE /api/v1/CaseDocument/ID
            </td>
            <td className="px-4 py-3 border border-white">id</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the file entry to delete.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
