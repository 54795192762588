export default function NextStepsContent() {
  return (
    <div>
      <p className="mb-4">
        This Getting Started guide provides an overview of the core concepts and
        entities in the JustinLegal API. To begin integrating with the API,
        you'll want to:
      </p>
      <ol className="list-decimal pl-6 space-y-2">
        <li>Obtain your API key for authentication.</li>
        <li>
          Familiarize yourself with the CaseFile structure and operations.
        </li>
        <li>
          Explore how to work with related entities like Contacts,
          Relationships, and Insurances.
        </li>
      </ol>
      <p className="mt-4">
        For more detailed information on each endpoint, request and response
        formats, and advanced features, please refer to the specific API
        reference documentation.
      </p>
    </div>
  );
}
