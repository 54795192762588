import { CodeSnippet } from "components/codeSnippet";

export default function ContactContent() {
  return (
    <div>
      <p className="mb-4">
        Contacts in the JustinLegal API represent individuals or organizations
        associated with legal cases. They are versatile entities that can be
        linked to CaseFiles through Relationships and play various roles in
        legal matters.
      </p>

      <h3 className="text-xl font-semibold mb-3">Post Body</h3>
      <CodeSnippet
        schema={`{
"type": "object",
"properties": {
"firstName": {
"type": "string",
"nullable": true
},
"lastName": {
"type": "string",
"nullable": true
},
"sex": {
"enum": [
"NonHuman",
"Diverse",
"Female",
"Male"
],
"type": "string"
},
"title": {
"type": "string",
"nullable": true
},
"dateOfBirth": {
"type": "string",
"format": "date-time",
"nullable": true
},
"type": {
"enum": [
"NaturalPerson",
"LegalPerson"
],
"type": "string"
},
"isUnknown": {
"type": "boolean"
},
"emailAddress": {
"type": "string",
"nullable": true
},
"name": {
"type": "string",
"nullable": true
},
"registerNumber": {
"type": "string",
"nullable": true
},
"registryCourt": {
"type": "string",
"nullable": true
},
"legalForm": {
"type": "string",
"nullable": true
},
"isVatExempt": {
"type": "boolean",
"nullable": true
},
"legalPersonType": {
"enum": [
"Company",
"Association",
"Foundation",
"Administration",
"Corporation",
"Institution"
],
"type": "string"
},
"bankInfo": {
"type": "object",
"properties": {
"bankAccountOwner": {
"type": "string",
"nullable": true
},
"iban": {
"type": "string",
"nullable": true
},
"bic": {
"type": "string",
"nullable": true
},
"bankName": {
"type": "string",
"nullable": true
},
"confirmedDirectDebitMandate": {
"type": "boolean"
}
},
"additionalProperties": false
},
"contactPersonId": {
"type": "string",
"format": "uuid",
"nullable": true
},
"legalRepresentatives": {
"type": "array",
"items": {
"type": "object",
"properties": {
"fullName": {
"type": "string",
"nullable": true
},
"role": {
"type": "string",
"nullable": true
},
"sex": {
"enum": [
"NonHuman",
"Diverse",
"Female",
"Male"
],
"type": "string"
}
},
"additionalProperties": false
},
"nullable": true
},
"addresses": {
"type": "array",
"items": {
"type": "object",
"properties": {
"street": {
"type": "string",
"nullable": true
},
"extendedAddress": {
"type": "string",
"nullable": true
},
"postCode": {
"type": "string",
"nullable": true
},
"city": {
"type": "string",
"nullable": true
},
"region": {
"type": "string",
"nullable": true
},
"country": {
"type": "string",
"nullable": true
}
},
"additionalProperties": false
},
"nullable": true
},
"phoneNumbers": {
"type": "array",
"items": {
"type": "object",
"properties": {
"number": {
"type": "string",
"nullable": true
},
"type": {
"enum": [
"Home",
"Work",
"Mobile",
"Fax"
],
"type": "string"
}
},
"additionalProperties": false
},
"nullable": true
},
"lawFirmId": {
"type": "string",
"format": "uuid"
}
},
"additionalProperties": false
}`}
        example={`{
"firstName": "John",
"lastName": "Doe",
"sex": "Male",
"title": "Mr.",
"dateOfBirth": "1980-01-01T00:00:00Z",
"type": "NaturalPerson",
"isUnknown": false,
"emailAddress": "john.doe@example.com",
"phoneNumbers": [
{
"number": "+1234567890",
"type": "Mobile"
}
],
"addresses": [
{
"street": "123 Main St",
"city": "Anytown",
"postCode": "12345",
"country": "USA"
}
],
"lawFirmId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
}`}
      />

      <h3 className="text-xl font-semibold my-3">Contact Types</h3>
      <p className="mb-2">Contacts can be of two types:</p>
      <ul className="list-disc pl-6 space-y-1">
        <li>
          <strong>Natural Person:</strong> Individual contacts with personal
          details.
        </li>
        <li>
          <strong>Legal Person:</strong> Organizations or entities with specific
          legal details.
        </li>
      </ul>

      <h3 className="text-xl font-semibold my-3">Special Considerations</h3>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <strong>Contact Person:</strong> Legal Persons (organizations) can
          have an associated Contact Person, which must be a Natural Person
          Contact.
        </li>
        <li>
          <strong>Type Changes:</strong> Changing a Contact from a Legal Person
          to a Natural Person is restricted if the Contact is associated with
          other Legal Persons.
        </li>
        <li>
          <strong>Insurance Associations:</strong> Contacts can be policy
          holders or co-insured parties in insurance policies.
        </li>
        <li>
          <strong>Deletion Restrictions:</strong> Contacts cannot be deleted if
          they have associated insurance policies or are referenced in custom
          fields.
        </li>
      </ul>
      <h3 className="text-2xl font-semibold my-3 mt-8">Parameters</h3>
      <table className="min-w-full bg-primary overflow-hidden">
        <thead>
          <tr>
            <th
              className="px-4 py-3 bg-primary-dark text-left font-bold text-lg border border-white"
              colSpan={3}
            >
              Contact
            </th>
          </tr>
          <tr className="bg-primary-dark">
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Operation
            </th>
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Field
            </th>
            <th className="px-4 py-2 text-left font-semibold border border-white">
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-3 border border-white" rowSpan={15}>
              POST /api/v1/Contact (Create)
            </td>
            <td className="px-4 py-3 border border-white">lawFirmId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the law firm associated with this
              contact.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">type</td>
            <td className="px-4 py-3 border border-white">
              Type of contact. Enum: NaturalPerson, LegalPerson.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">firstName</td>
            <td className="px-4 py-3 border border-white">
              First name of the contact (for natural persons).
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">lastName</td>
            <td className="px-4 py-3 border border-white">
              Last name of the contact (for natural persons).
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">sex</td>
            <td className="px-4 py-3 border border-white">
              Sex of the contact. Enum: NonHuman, Diverse, Female, Male.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">title</td>
            <td className="px-4 py-3 border border-white">
              Title of the contact (e.g., Dr., Prof.).
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">dateOfBirth</td>
            <td className="px-4 py-3 border border-white">
              Date of birth of the contact.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">isUnknown</td>
            <td className="px-4 py-3 border border-white">
              Boolean flag indicating if the contact's details are unknown.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">emailAddress</td>
            <td className="px-4 py-3 border border-white">
              Email address of the contact.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">name</td>
            <td className="px-4 py-3 border border-white">
              Name of the contact (for legal persons).
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">registerNumber</td>
            <td className="px-4 py-3 border border-white">
              Registration number (for legal persons).
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">registryCourt</td>
            <td className="px-4 py-3 border border-white">
              Registry court (for legal persons).
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">legalForm</td>
            <td className="px-4 py-3 border border-white">
              Legal form of the organization (for legal persons).
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">isVatExempt</td>
            <td className="px-4 py-3 border border-white">
              Boolean flag indicating if the contact is exempt from VAT.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">legalPersonType</td>
            <td className="px-4 py-3 border border-white">
              Type of legal person. Enum: Company, Association, Foundation,
              Administration, Corporation, Institution.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white" rowSpan={6}>
              PUT /api/v1/Contact/Contact ID (Update)
            </td>
            <td className="px-4 py-3 border border-white">contactId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the contact to update.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">bankInfo</td>
            <td className="px-4 py-3 border border-white">
              Object containing bank account information (bankAccountOwner,
              iban, bic, bankName, confirmedDirectDebitMandate).
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">contactPersonId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the contact person (for legal
              persons).
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              legalRepresentatives
            </td>
            <td className="px-4 py-3 border border-white">
              Array of legal representatives (for legal persons), each
              containing fullName, role, and sex.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">addresses</td>
            <td className="px-4 py-3 border border-white">
              Array of addresses associated with the contact.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">phoneNumbers</td>
            <td className="px-4 py-3 border border-white">
              Array of phone numbers associated with the contact.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              GET /api/v1/Contact/Contact ID
            </td>
            <td className="px-4 py-3 border border-white">contactId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the contact to retrieve.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-3 border border-white">
              DELETE /api/v1/Contact/Contact ID
            </td>
            <td className="px-4 py-3 border border-white">contactId</td>
            <td className="px-4 py-3 border border-white">
              Unique identifier (UUID) of the contact to delete.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
